// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    notification: null
};

// ==============================|| SLICE - MENU ||============================== //

const uiActions = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        showNotification(state, action) {
            state.notification = action.payload;
        },
        clearNotification(state) {
            state.notification = null;
        }
    }
});

export default uiActions.reducer;

export const { showNotification, clearNotification } = uiActions.actions;
