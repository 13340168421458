import PropTypes from 'prop-types';
import { useMemo } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery, Switch, FormControlLabel, FormGroup } from '@mui/material';

// project import
import DrawerHeader from './DrawerHeader';
import DrawerContent from './DrawerContent';
import MiniDrawerStyled from './MiniDrawerStyled';
import { drawerWidth } from 'config';
import { useDispatch, useSelector } from 'react-redux';
import { setMode } from 'store/reducers/mode';
import { getOrders } from 'store/reducers/actions';

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

const MainDrawer = ({ open, handleDrawerToggle, window }) => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    const dispatch = useDispatch();
    const { live } = useSelector((state) => state.mode);

    // responsive drawer container
    const container = window !== undefined ? () => window().document.body : undefined;

    // header content
    const drawerContent = useMemo(() => <DrawerContent />, []);
    const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);

    const handleChange = () => {
        dispatch(setMode());
    };
    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1300 }} aria-label="mailbox folders">
            {!matchDownMD ? (
                <MiniDrawerStyled variant="permanent" open={open}>
                    {drawerHeader}
                    {drawerContent}
                    <Box sx={{ alignItems: 'flex-end', margin: '0px 90% 46px 0px' }}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={live} onChange={handleChange} />}
                                labelPlacement="start"
                                label="It's live data"
                            />{' '}
                        </FormGroup>
                    </Box>
                </MiniDrawerStyled>
            ) : (
                <Drawer
                    container={container}
                    variant="temporary"
                    open={open}
                    onClose={handleDrawerToggle}
                    ModalProps={{ keepMounted: true }}
                    sx={{
                        display: { xs: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            borderRight: `1px solid ${theme.palette.divider}`,
                            backgroundImage: 'none',
                            boxShadow: 'inherit'
                        }
                    }}
                >
                    {open && drawerHeader}
                    {open && drawerContent}
                    <Box sx={{ alignItems: 'flex-end', margin: '0px 90% 46px 0px' }}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={live} onChange={handleChange} />}
                                labelPlacement="start"
                                label="It's live data"
                            />{' '}
                        </FormGroup>
                    </Box>
                </Drawer>
            )}
        </Box>
    );
};

MainDrawer.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default MainDrawer;
