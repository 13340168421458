import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

//common way to create private route
const ReservationRoute = ({ children, ...rest /*using rest parameter*/ }) => {
    const authState = useSelector((state) => state.auth);
    return authState.user &&
        ((authState.user.account && authState.user.account && authState.user.account.isHotel) || authState.user.role == 'root') ? (
        children
    ) : (
        <Navigate to={`/`} />
    );
};

export default ReservationRoute;
