import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import PrivateRootRoute from './PrivateRootRoute';

// render - login
const Register = Loadable(lazy(() => import('pages/account/Register')));
const Credentials = Loadable(lazy(() => import('pages/account/credentials')));

// ==============================|| AUTH ROUTING ||============================== //

const AccountRoutes = {
    path: '/account',
    element: <MinimalLayout />,
    children: [
        {
            path: 'add',
            element: (
                // <PrivateRootRoute>
                <Register />
                // </PrivateRootRoute>
            )
        },
        {
            path: 'credentials',
            element: (
                // <PrivateRootRoute>
                <Credentials />
                // </PrivateRootRoute>
            )
        }
    ]
};

export default AccountRoutes;
