import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

//common way to create private route
const PrivateRoute = ({ children, ...rest /*using rest parameter*/ }) => {
    const authState = useSelector((state) => state.auth);
    return !authState.authenticated && !authState.loading ? (
        <Navigate to={`/login?returnUrl=${encodeURIComponent(window.location.pathname)}`} />
    ) : (
        children
    );
};

export default PrivateRoute;
