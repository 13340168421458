// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import order from './order';
import ui from './ui-slice';
import auth from './auth';
import account from './account';
import paymentPage from './paymentPage';
import reservation from './reservation';
import paymentRequest from './paymentRequest';
import template from './template';
import mode from './mode';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, order, auth, ui, account, mode, paymentPage, reservation, paymentRequest, template });

export default reducers;
