// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    live: true
};

// ==============================|| SLICE - MENU ||============================== //

const mode = createSlice({
    name: 'mode',
    initialState,
    reducers: {
        setMode(state) {
            state.live = !state.live;
        }
    }
});

export default mode.reducer;

export const { setMode } = mode.actions;
