// types
import { createSlice } from '@reduxjs/toolkit';
// initial state
const initialState = {
    authenticated: false,
    loading: false,
    user: null,
    token: null
};

// ==============================|| SLICE - AUTH ||============================== //

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login(state, action) {
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('user', JSON.stringify(action.payload.user));
            state.authenticated = action.payload.authenticated;
            state.loading = action.payload.loading;
            state.user = action.payload.user;
            state.token = action.payload.token;
        },
        logout(state) {
            state.authenticated = false;
            state.token = null;
            localStorage.removeItem('token');
            localStorage.removeItem('user');
        },
        isUserLogedIn(state) {
            if (localStorage.token) {
                state.user = JSON.parse(localStorage.getItem('user'));
                state.authenticated = true;
                state.token = localStorage.token;
            }
        }
    }
});

export default auth.reducer;

export const { login, logout, isUserLogedIn } = auth.actions;
