// types
import { createSlice } from '@reduxjs/toolkit';
// initial state
const initialState = {
    reservations: null,
    totalReservations: null,
    currentPage: null,
    paidReservations: null,
    unpaidReservations: null,
    pageSize: null,
    loading: false
};

// ==============================|| SLICE - KASHIER ||============================== //

const reservation = createSlice({
    name: 'reservation',
    initialState,
    reducers: {
        setReservations(state, action) {
            state.reservations = action.payload.reservations;
            state.totalReservations = action.payload.pagination.totalDocs;
            state.paidReservations = action.payload.pagination.paidReservations;
            state.unpaidReservations = action.payload.pagination.unpaidReservations;
            state.currentPage = action.payload.pagination.currentPage;
            state.pageSize = action.payload.pagination.pageSize;
            state.loading = false;
        },
        updateReservation(state, action) {
            state.guests = state.reservations.map((reservation) =>
                reservation._id === action.payload.reservation._id ? action.payload.reservation : reservation
            );
            state.loading = false;
        }
    }
});

export default reservation.reducer;

export const { setReservations, updateReservation } = reservation.actions;
