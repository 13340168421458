import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import PrivateRoute from './PrivateRoute';
import ReservationRoute from './ReservationRoute';
import PaymentRequestRoute from './PaymentRequestRoute';
// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const Reservation = Loadable(lazy(() => import('pages/reservation')));
const PaymentRequest = Loadable(lazy(() => import('pages/paymentRequest')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: (
                <PrivateRoute>
                    <DashboardDefault />
                </PrivateRoute>
            )
        },
        {
            path: '/reservation',
            element: (
                <PrivateRoute>
                    <ReservationRoute>
                        <Reservation />
                    </ReservationRoute>
                </PrivateRoute>
            )
        },
        {
            path: '/paymentRequest',
            element: (
                <PrivateRoute>
                    <PaymentRequestRoute>
                        <PaymentRequest />
                    </PaymentRequestRoute>
                </PrivateRoute>
            )
        }
        // {
        //     path: 'color',
        //     element: (
        //         <PrivateRoute>
        //             <Color />
        //         </PrivateRoute>
        //     )
        // },
        // {
        //     path: 'dashboard',
        //     children: [
        //         {
        //             path: 'default',
        //             element: (
        //                 <PrivateRoute>
        //                     <DashboardDefault />{' '}
        //                 </PrivateRoute>
        //             )
        //         }
        //     ]
        // },
        // {
        //     path: 'sample-page',
        //     element: <SamplePage />
        // },
        // {
        //     path: 'shadow',
        //     element: <Shadow />
        // },
        // {
        //     path: 'typography',
        //     element: <Typography />
        // },
        // {
        //     path: 'icons/ant',
        //     element: <AntIcons />
        // }
    ]
};

export default MainRoutes;
