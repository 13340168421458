// types
import { createSlice } from '@reduxjs/toolkit';
// initial state
const initialState = {
    accounts: null,
    account: null,
    currentPage: null,
    pageSize: null,
    totalAccounts: null
};

// ==============================|| SLICE - KASHIER ||============================== //

const account = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setAccounts(state, action) {
            state.accounts = action.payload.accounts;
            state.currentPage = action.payload.pagination.currentPage;
            state.pageSize = action.payload.pagination.pageSize;
        },
        setAccount(state, action) {
            state.account = action.payload.account;
        }
    }
});

export default account.reducer;

export const { setAccounts, setAccount } = account.actions;
