// types
import { createSlice } from '@reduxjs/toolkit';
// initial state
const initialState = {
    paymentPages: null
};

// ==============================|| SLICE - KASHIER ||============================== //

const paymentPage = createSlice({
    name: 'paymentPage',
    initialState,
    reducers: {
        setPaymentPages(state, action) {
            state.paymentPages = action.payload.paymentPages;
        }
    }
});

export default paymentPage.reducer;

export const { setPaymentPages } = paymentPage.actions;
