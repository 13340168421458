// types
import { createSlice } from '@reduxjs/toolkit';
// initial state
const initialState = {
    orders: null,
    totalOrders: null,
    currentPage: null,
    pageSize: null,
    pendingOrders: null,
    fulfilledOrders: null,
    processingOrders: null
};

// ==============================|| SLICE - KASHIER ||============================== //

const order = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setOrders(state, action) {
            state.orders = action.payload.orders;
            state.totalOrders = action.payload.pagination.totalDocs;
            state.currentPage = action.payload.pagination.currentPage;
            state.pageSize = action.payload.pagination.pageSize;
            state.pendingOrders = action.payload.pagination.pendingOrders;
            state.fulfilledOrders = action.payload.pagination.fulfilledOrders;
            state.processingOrders = action.payload.pagination.processingOrders;
        },
        updateOrder(state, action) {
            state.orders = state.orders.map((order) => (order._id === action.payload.order._id ? action.payload.order : order));
            if (action.payload.order.status == 'pending') {
                state.pendingOrders = state.pendingOrders + 1;
                state.fulfilledOrders = state.fulfilledOrders - 1;
            } else {
                state.pendingOrders = state.pendingOrders - 1;
                state.fulfilledOrders = state.fulfilledOrders + 1;
            }
        }
    }
});

export default order.reducer;

export const { setOrders, updateOrder } = order.actions;
