// project import
import React, { useEffect, useRef } from 'react';
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { useSelector, useDispatch } from 'react-redux';
import { CustomAlert } from 'components/Alert';
import { isUserLogedIn } from 'store/reducers/auth';
import Pusher from 'pusher-js';
import { getOrders, getPaymentRequests, getReservations, initialData, ordersSync } from 'store/reducers/actions';
import { useSearchParams } from 'react-router-dom';
import NotificationSound from './assets/sounds/notification-sound.mp3';
import Audio from 'components/Audio';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
    const authState = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const audioPlayer = useRef(null);
    const firstRender = useRef(true);
    const { live } = useSelector((state) => state.mode);

    useEffect(() => {
        if (!authState.authenticated) {
            // userLoaded();
            dispatch(isUserLogedIn());
        } else {
            dispatch(
                initialData({
                    url: '/initial'
                })
            );
        }
        //eslint-disable-next-line
    }, [authState.authenticated]);

    useEffect(() => {
        // Enable pusher logging - don't include this in production
        if (authState.user && authState.user.account) {
            Pusher.logToConsole = true;

            var pusher = new Pusher('8d72275bfe766ab52ad6', {
                cluster: 'eu'
            });

            var channel = pusher.subscribe(`${authState.user.account.merchantId}`);
            channel.bind(`newOrder`, async function (data) {
                let query = '/?';
                for (const entry of searchParams.entries()) {
                    const [param, value] = entry;
                    query += `${param}=${value}&`;
                }

                await dispatch(
                    getOrders({
                        url: `/orders${query}`,
                        notShowIntentMessage: true,
                        customMessage: 'You have a new order'
                    })
                );
                audioPlayer.current.play();

                dispatch(
                    ordersSync({
                        url: '/orders/sync',
                        notShowMessage: true
                    })
                );
            });

            channel.bind(`newPaidReservation`, async function (data) {
                let query = '/?';
                for (const entry of searchParams.entries()) {
                    const [param, value] = entry;
                    query += `${param}=${value}&`;
                }

                await dispatch(
                    getReservations({
                        url: `/reservations${query}`,
                        notShowIntentMessage: true,
                        customMessage: 'You have a paid reservation'
                    })
                );
                audioPlayer.current.play();
            });

            channel.bind(`newPaidPaymentRequest`, async function (data) {
                let query = '/?';
                for (const entry of searchParams.entries()) {
                    const [param, value] = entry;
                    query += `${param}=${value}&`;
                }

                await dispatch(
                    getPaymentRequests({
                        url: `/paymentRequests${query}`,
                        notShowIntentMessage: true,
                        customMessage: 'You have a paid Payment Request'
                    })
                );
                audioPlayer.current.play();
            });
        }
    }, [authState.user]);

    useEffect(() => {
        if (!firstRender.current)
            dispatch(
                initialData({
                    url: '/initial'
                })
            );
        else firstRender.current = false;
    }, [live]);

    return (
        <ThemeCustomization>
            <ScrollTop>
                <CustomAlert />
                <Audio audioPlayerRef={audioPlayer} src={NotificationSound} type="audio/mp3"></Audio>
                <Routes />
            </ScrollTop>
        </ThemeCustomization>
    );
};

export default App;
