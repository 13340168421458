import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import PrivateRootRoute from './PrivateRootRoute';

// render - login
const Register = Loadable(lazy(() => import('pages/template/Template')));

// ==============================|| AUTH ROUTING ||============================== //

const TemplateRoutes = {
    path: '/template',
    element: <MinimalLayout />,
    children: [
        {
            path: 'add',
            element: (
                // <PrivateRootRoute>
                <Register />
                // </PrivateRootRoute>
            )
        }
    ]
};

export default TemplateRoutes;
