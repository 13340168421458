import React, { useEffect } from 'react';
import { Alert, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { clearNotification } from 'store/reducers/ui-slice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
    alert: {
        position: 'fixed',
        right: '-450px',
        top: '150px',
        animation: '$pulse 2s 1',
        animationFillMode: 'forwards'
    },
    '@keyframes pulse': {
        '0%': {
            right: '0px'
        },
        '100%': {
            right: '100px'
        }
    }
});

export const CustomAlert = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { notification } = useSelector((state) => state.ui);

    useEffect(() => {
        if (notification) {
            var id = setTimeout(() => {
                dispatch(clearNotification());
            }, 5000);
        }
        return () => clearTimeout(id);
    }, [notification]);

    return notification ? (
        <Box sx={{ zIndex: '9999' }}>
            <Alert className={classes.alert} severity={notification.status} sx={{ zIndex: '9999' }}>
                {notification.message}
            </Alert>
        </Box>
    ) : null;
};
