import axios from '../../helper/index';
import { showNotification } from './ui-slice';
import { login } from './auth';
import { setOrders, updateOrder } from './order';
import { setReservations, updateReservation } from './reservation';
import { setAccounts, setAccount } from './account';
import { setPaymentPages } from './paymentPage';
import { setTemplate, setTemplates } from './template';
import { setPaymentRequests, updatePaymentRequest, addPaymentRequest } from './paymentRequest';

export const loginRequest = (formData) => {
    return async (dispatch) => {
        dispatch(
            showNotification({
                status: 'warning',
                title: 'Pending...',
                message: 'Sending Request'
            })
        );

        const executeRequest = () => {
            return axios.post('/auth/signin', formData, {
                headers: { 'Content-Type': 'application/json' }
            });
        };

        try {
            const response = await executeRequest();
            const data = response.data;

            dispatch(
                login({
                    user: data.response.user,
                    token: data.response.token,
                    authenticated: true,
                    loading: false
                })
            );

            dispatch(
                showNotification({
                    status: 'success',
                    title: 'Success...',
                    message: response.data.messages.en
                })
            );
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};

export const initialData = (options) => {
    return async (dispatch) => {
        !(options.notShowMessage || options.notShowIntentMessage) &&
            dispatch(
                showNotification({
                    status: 'warning',
                    title: 'Pending...',
                    message: 'Sending Request ....'
                })
            );

        const executeRequest = () => {
            return axios.get(options.url, {
                headers: { 'Content-Type': 'application/json' }
            });
        };

        try {
            const response = await executeRequest();
            dispatch(
                setOrders({
                    ...response.data.response.order
                })
            );

            dispatch(
                setReservations({
                    ...response.data.response.reservation
                })
            );

            dispatch(
                setPaymentPages({
                    ...response.data.response.paymentPage
                })
            );

            dispatch(
                setPaymentRequests({
                    ...response.data.response.paymentRequest
                })
            );

            !options.notShowMessage &&
                dispatch(
                    showNotification({
                        status: 'success',
                        title: 'Success...',
                        message: options.customMessage ? options.customMessage : response.data.messages.en
                    })
                );
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};

export const getOrders = (options) => {
    return async (dispatch) => {
        !(options.notShowMessage || options.notShowIntentMessage) &&
            dispatch(
                showNotification({
                    status: 'warning',
                    title: 'Pending...',
                    message: 'Sending Request ....'
                })
            );

        const executeRequest = () => {
            return axios.get(options.url, {
                headers: { 'Content-Type': 'application/json' }
            });
        };

        try {
            const response = await executeRequest();
            dispatch(
                setOrders({
                    ...response.data.response
                })
            );

            !options.notShowMessage &&
                dispatch(
                    showNotification({
                        status: 'success',
                        title: 'Success...',
                        message: options.customMessage ? options.customMessage : response.data.messages.en
                    })
                );
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};

export const getReservations = (options) => {
    return async (dispatch) => {
        !(options.notShowMessage || options.notShowIntentMessage) &&
            dispatch(
                showNotification({
                    status: 'warning',
                    title: 'Pending...',
                    message: 'Sending Request ....'
                })
            );

        const executeRequest = () => {
            return axios.get(options.url, {
                headers: { 'Content-Type': 'application/json' }
            });
        };

        try {
            const response = await executeRequest();
            dispatch(
                setReservations({
                    ...response.data.response
                })
            );

            !options.notShowMessage &&
                dispatch(
                    showNotification({
                        status: 'success',
                        title: 'Success...',
                        message: options.customMessage ? options.customMessage : response.data.messages.en
                    })
                );
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};

export const exportReservations = (options) => {
    return async (dispatch) => {
        dispatch(
            showNotification({
                status: 'warning',
                title: 'Pending...',
                message: 'Sending Request To Server'
            })
        );

        const executeRequest = async () => {
            return axios.get(options.url, options.config);
        };

        try {
            const response = await executeRequest();

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = mm + '-' + dd + '-' + yyyy;
            link.setAttribute('download', `Reservations_${today}.xlsx`);
            document.body.appendChild(link);
            link.click();

            dispatch(
                showNotification({
                    status: 'success',
                    title: 'Success...',
                    message: 'Reservations has been exported successfully'
                })
            );
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};

export const getPaymentPages = (options) => {
    return async (dispatch) => {
        !(options.notShowMessage || options.notShowIntentMessage) &&
            dispatch(
                showNotification({
                    status: 'warning',
                    title: 'Pending...',
                    message: 'Sending Request ....'
                })
            );

        const executeRequest = () => {
            return axios.get(options.url, {
                headers: { 'Content-Type': 'application/json' }
            });
        };

        try {
            const response = await executeRequest();
            dispatch(
                setPaymentPages({
                    ...response.data.response
                })
            );

            !options.notShowMessage &&
                dispatch(
                    showNotification({
                        status: 'success',
                        title: 'Success...',
                        message: options.customMessage ? options.customMessage : response.data.messages.en
                    })
                );
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};

export const editOrderStatus = (options) => {
    return async (dispatch) => {
        dispatch(
            showNotification({
                status: 'warning',
                title: 'Pending...',
                message: 'Sending Request ....'
            })
        );

        const executeRequest = () => {
            return axios.put(options.url, options.data, {
                headers: { 'Content-Type': 'application/json' }
            });
        };

        try {
            const response = await executeRequest();
            dispatch(
                updateOrder({
                    ...response.data.response
                })
            );

            dispatch(
                showNotification({
                    status: 'success',
                    title: 'Success...',
                    message: response.data.messages.en
                })
            );

            var url = window.location.href;
            var queryString = url.split('?')[1];

            dispatch(
                getOrders({
                    url: `/orders?${queryString}`,
                    notShowMessage: true
                })
            );
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
            throw Error();
        }
    };
};

export const ordersSync = (options) => {
    return async (dispatch) => {
        !options.notShowMessage &&
            dispatch(
                showNotification({
                    status: 'warning',
                    title: 'Pending...',
                    message: 'Sending Request ....'
                })
            );

        const executeRequest = () => {
            return axios.get(options.url, {
                headers: { 'Content-Type': 'application/json' }
            });
        };

        try {
            const response = await executeRequest();

            !options.notShowMessage &&
                dispatch(
                    showNotification({
                        status: 'success',
                        title: 'Success...',
                        message: response.data.messages.en
                    })
                );
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};

export const pagesSync = (options) => {
    return async (dispatch) => {
        dispatch(
            showNotification({
                status: 'warning',
                title: 'Pending...',
                message: 'Sending Request...'
            })
        );

        const executeRequest = () => {
            return axios.get(options.url, options.data, {
                headers: { 'Content-Type': 'application/json' }
            });
        };

        try {
            const response = await executeRequest();

            dispatch(
                showNotification({
                    status: 'success',
                    title: 'Success...',
                    message: response.data.messages.en
                })
            );
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};

export const createAccount = (options) => {
    return async (dispatch) => {
        dispatch(
            showNotification({
                status: 'warning',
                title: 'Pending...',
                message: 'Sending Request...'
            })
        );

        const executeRequest = () => {
            return axios.post(options.url, options.data, {
                headers: { 'Content-Type': 'application/json' }
            });
        };

        try {
            const response = await executeRequest();

            dispatch(
                setAccount({
                    ...response.data.response
                })
            );

            dispatch(
                showNotification({
                    status: 'success',
                    title: 'Success...',
                    message: response.data.messages.en
                })
            );
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};

export const getAccounts = (options) => {
    return async (dispatch) => {
        dispatch(
            showNotification({
                status: 'warning',
                title: 'Pending...',
                message: 'Sending Request...'
            })
        );

        const executeRequest = () => {
            return axios.get(options.url, {
                headers: { 'Content-Type': 'application/json' }
            });
        };

        try {
            const response = await executeRequest();

            dispatch(
                setAccounts({
                    ...response.data.response
                })
            );

            dispatch(
                showNotification({
                    status: 'success',
                    title: 'Success...',
                    message: response.data.messages.en
                })
            );
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};

export const createTemplate = (options) => {
    return async (dispatch) => {
        dispatch(
            showNotification({
                status: 'warning',
                title: 'Pending...',
                message: 'Sending Request...'
            })
        );

        const executeRequest = () => {
            return axios.post(options.url, options.data, {
                headers: { 'Content-Type': 'application/json' }
            });
        };

        try {
            const response = await executeRequest();

            dispatch(
                setTemplate({
                    ...response.data.response
                })
            );

            dispatch(
                showNotification({
                    status: 'success',
                    title: 'Success...',
                    message: response.data.messages.en
                })
            );
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};

export const getTemplates = (options) => {
    return async (dispatch) => {
        dispatch(
            showNotification({
                status: 'warning',
                title: 'Pending...',
                message: 'Sending Request...'
            })
        );

        const executeRequest = () => {
            return axios.get(options.url, {
                headers: { 'Content-Type': 'application/json' }
            });
        };

        try {
            const response = await executeRequest();

            dispatch(
                setTemplates({
                    ...response.data.response
                })
            );

            dispatch(
                showNotification({
                    status: 'success',
                    title: 'Success...',
                    message: response.data.messages.en
                })
            );
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};

export const registerUser = (options) => {
    return async (dispatch) => {
        dispatch(
            showNotification({
                status: 'warning',
                title: 'Pending...',
                message: 'Sending Request...'
            })
        );

        const executeRequest = () => {
            return axios.post(options.url, options.data, {
                headers: { 'Content-Type': 'application/json' }
            });
        };

        try {
            const response = await executeRequest();

            dispatch(
                showNotification({
                    status: 'success',
                    title: 'Success...',
                    message: response.data.messages.en
                })
            );
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};



export const createCrential = (options) => {
    return async (dispatch) => {
        dispatch(
            showNotification({
                status: 'warning',
                title: 'Pending...',
                message: 'Sending Request...'
            })
        );

        const executeRequest = () => {
            return axios.post(options.url, options.data, {
                headers: { 'Content-Type': 'application/json' }
            });
        };

        try {
            const response = await executeRequest();

            dispatch(
                showNotification({
                    status: 'success',
                    title: 'Success...',
                    message: response.data.messages.en
                })
            );
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};


export const shareReservation = (options) => {
    return async (dispatch) => {
        dispatch(
            showNotification({
                status: 'warning',
                title: 'Pending...',
                message: 'Sending Request To Server'
            })
        );

        const executeRequest = async () => {
            return axios.post(options.url, options.data, options.headers);
        };

        try {
            const response = await executeRequest();
            dispatch(
                updateReservation({
                    ...response.data.response
                })
            );
            dispatch(
                showNotification({
                    status: 'success',
                    title: 'Success...',
                    message: response.data.messages.en
                })
            );
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};

export const getPaymentRequests = (options) => {
    return async (dispatch) => {
        !(options.notShowMessage || options.notShowIntentMessage) &&
            dispatch(
                showNotification({
                    status: 'warning',
                    title: 'Pending...',
                    message: 'Sending Request ....'
                })
            );

        const executeRequest = () => {
            return axios.get(options.url, {
                headers: { 'Content-Type': 'application/json' }
            });
        };

        try {
            const response = await executeRequest();
            dispatch(
                setPaymentRequests({
                    ...response.data.response
                })
            );

            !options.notShowMessage &&
                dispatch(
                    showNotification({
                        status: 'success',
                        title: 'Success...',
                        message: options.customMessage ? options.customMessage : response.data.messages.en
                    })
                );
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};

export const createPaymentRequest = (options) => {
    return async (dispatch) => {
        dispatch(
            showNotification({
                status: 'warning',
                title: 'Pending...',
                message: 'Sending Request To Server'
            })
        );

        const executeRequest = async () => {
            return await axios.post(options.url, options.data, options.headers);
        };

          try {
            const response = await executeRequest();
              dispatch(
                addPaymentRequest({
                    ...response.data.response
                })
            );
             dispatch(
                showNotification({
                    status: 'success',
                    title: 'Success...',
                    message: response.data.messages.en
                })
            );
         
                window.open(`https://kashier-usd.herokuapp.com?paymentRequestId=${response?.data?.response?.paymentRequest?._id}`, '_blank')

            
           
        } catch (error) {
             dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};

export const editPaymentRequest = (options) => {
    return async (dispatch) => {
        dispatch(
            showNotification({
                status: 'warning',
                title: 'Pending...',
                message: 'Sending Request To Server'
            })
        );

        const executeRequest = async () => {
            return axios.put(options.url, options.data, options.headers);
        };

        try {
            const response = await executeRequest();
            dispatch(
                updatePaymentRequest({
                    ...response.data.response
                })
            );
            dispatch(
                showNotification({
                    status: 'success',
                    title: 'Success...',
                    message: response.data.messages.en
                })
            );
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};

export const cancelPaymentRequest = (options) => {
    return async (dispatch) => {
        dispatch(
            showNotification({
                status: 'warning',
                title: 'Pending...',
                message: 'Sending Request To Server'
            })
        );

        const executeRequest = async () => {
            return axios.patch(options.url, options.data, options.headers);
        };

        try {
            const response = await executeRequest();
            dispatch(
                updatePaymentRequest({
                    ...response.data.response
                })
            );

            dispatch(
                showNotification({
                    status: 'success',
                    title: 'Success...',
                    message: response.data.messages.en
                })
            );
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};

export const sharePaymentRequest = (options) => {
    return async (dispatch) => {
        dispatch(
            showNotification({
                status: 'warning',
                title: 'Pending...',
                message: 'Sending Request To Server'
            })
        );

        const executeRequest = async () => {
            return axios.post(options.url, options.data, options.headers);
        };

        try {
            const response = await executeRequest();
            dispatch(
                updatePaymentRequest({
                    ...response.data.response
                })
            );
            console.log('sahreddddddddddddddddddddddddddddd');
            dispatch(
                showNotification({
                    status: 'success',
                    title: 'Success...',
                    message: response.data.messages.en
                })
            );
            console.log('nioooooooooooooooooooooooooooooooooooooo');
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};

export const reconcilePaymentRequest = (options) => {
    return async (dispatch) => {
        dispatch(
            showNotification({
                status: 'warning',
                title: 'Pending...',
                message: 'Sending Request To Server'
            })
        );

        const executeRequest = async () => {
            return axios.get(options.url, options.headers);
        };

        try {
            const response = await executeRequest();
            // dispatch(
            //     updatePaymentRequest({
            //         ...response.data.response
            //     })
            // );
            dispatch(
                showNotification({
                    status: 'success',
                    title: 'Success...',
                    message: response.data.messages.en 
                })
            );
        } catch (error) {
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};

export const exportPaymentRequests = (options) => {
    return async (dispatch) => {
        dispatch(
            showNotification({
                status: 'warning',
                title: 'Pending...',
                message: 'Sending Request To Server'
            })
        );

        const executeRequest = async () => {
            return axios.get(options.url, options.config);
        };

        try {
            const response = await executeRequest();

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = mm + '-' + dd + '-' + yyyy;
            link.setAttribute('download', `PaymentRequests_${today}.xlsx`);
            document.body.appendChild(link);
            link.click();

            dispatch(
                showNotification({
                    status: 'success',
                    title: 'Success...',
                    message: 'Reservations has been exported successfully'
                })
            );
        } catch (error) {
            console.log(error);
            dispatch(
                showNotification({
                    status: 'error',
                    title: 'Error...',
                    message:
                        error.response && error.response.data && error.response.data.messages && error.response.data.messages.en
                            ? error.response.data.messages.en
                            : 'Request is failed'
                })
            );
        }
    };
};
