// types
import { createSlice } from '@reduxjs/toolkit';
// initial state
const initialState = {
    paymentRequests: null,
    totalPaymentRequests: null,
    currentPage: null,
    paidPaymentRequests: null,
    unpaidPaymentRequests: null,
    pageSize: null,
    loading: false
};

// ==============================|| SLICE - KASHIER ||============================== //

const paymentRequest = createSlice({
    name: 'paymentRequest',
    initialState,
    reducers: {
        setPaymentRequests(state, action) {
            state.paymentRequests = action.payload.paymentRequests;
            state.totalPaymentRequests = action.payload.pagination.totalDocs;
            state.paidPaymentRequests = action.payload.pagination.paidPaymentRequests;
            state.unpaidPaymentRequests = action.payload.pagination.unpaidPaymentRequests;
            state.currentPage = action.payload.pagination.currentPage;
            state.pageSize = action.payload.pagination.pageSize;
            state.loading = false;
        },
        addPaymentRequest(state, action) {
            console.log(action.payload.paymentRequest);

            state.paymentRequests = [action.payload.paymentRequest, ...state.paymentRequests];
            state.loading = false;
        },
        updatePaymentRequest(state, action) {
            state.paymentRequests = state.paymentRequests.map((paymentRequest) =>
                paymentRequest._id === action.payload.paymentRequest._id ? action.payload.paymentRequest : paymentRequest
            );
            state.loading = false;
        }
    }
});

export default paymentRequest.reducer;

export const { setPaymentRequests, updatePaymentRequest, addPaymentRequest } = paymentRequest.actions;
