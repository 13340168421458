import React from 'react';

export default Audio = ({ audioPlayerRef, src, type }) => {
    return (
        <audio ref={audioPlayerRef}>
            <source src={src} type={type} />
            <track kind="captions" srcLang="en" label="english_captions" />
        </audio>
    );
};
