import axios from 'axios';
import { logout } from 'store/reducers/auth';
import { store } from '../store';

const token = window.localStorage.getItem('token');

const axiosIntance = axios.create({
    // baseURL: 'http://localhost:5000/api/v1',
    baseURL: 'https://dashboard.vas.kashier.io/api/v1',
    headers: {
        Authorization: token ? `${token}` : ''
    }
    // timeout: 2000,
});

axiosIntance.interceptors.request.use((req) => {
    const {
        auth,
        mode: { live }
    } = store.getState();
    if (auth.token) {
        req.headers.Authorization = `${auth.token}`;
        req.headers.mode = live ? 'live' : 'test';
    }
    return req;
});

axiosIntance.interceptors.response.use(
    (res) => {
        return res;
    },
    (error) => {
        console.log(error);
        const status = error.response.status;
        if (status === 401) {
            localStorage.clear();
            store.dispatch(logout({ authenticated: false }));
        }
        return Promise.reject(error);
    }
);

export default axiosIntance;
